import { getClient } from "./client";
import {
  ApiAvailableEvent,
  ApiGamePrize,
  ApiGameSponsor,
  ApiMatchup,
  ApiPlayerDetails,
  ApiTeam,
  ApiEvent,
  GameStatus,
} from "./shared";

export type ApiGameDetails = {
  id: string;

  tokensGiven: number;
  tokenSteps: number[];
  availableEvents: ApiAvailableEvent[];
  gameSponsor: null | ApiGameSponsor;
  players: ApiPlayerDetails[];
  prizes: ApiGamePrize[];
  team: ApiTeam;

  /** Full UTC */
  timestamp: string;
  status: GameStatus;
} & (
  | { typeEvent: null; typeMatchup: ApiMatchup }
  | { typeEvent: ApiEvent; typeMatchup: null }
);

export const getGameById = (gameId: string) =>
  getClient().get(`/api/games/${gameId}`).json<ApiGameDetails>();
