import { useQuery } from "react-query";
import uniq from "lodash/uniq";

import {
  getGameById,
  getMessagesByGameId,
  getPicksByGameId,
  getScoringAndRank,
} from "../../api";
import { queryClient } from "../../components";
import { useSetting } from "../../settings";

export function useGameQuery(gameId: string) {
  return useQuery([gameId, "games"], () => getGameById(gameId), {
    refetchInterval: useSetting("queries.polling.very-long"),
  });
}

useGameQuery.prefetch = async (gameId: string) => {
  await queryClient.prefetchQuery([gameId, "games"], () => getGameById(gameId));
  const game = await queryClient.fetchQuery([gameId, "games"], () =>
    getGameById(gameId),
  );

  const imageURLs = uniq(
    [
      game?.gameSponsor?.image.url,
      ...(game?.players.map((player) => player.headshot.url) ?? []),
      game?.team?.logo.url,
    ].filter((value): value is string => typeof value === "string"),
  );

  for (const src of imageURLs) {
    const img = new Image();
    img.src = src;
  }

  return game;
};

export function useMessagesQuery(gameId: string) {
  return useQuery(
    [gameId, "game-messages"],
    () => getMessagesByGameId(gameId),
    {
      refetchInterval: useSetting("queries.polling.short"),
    },
  );
}

useMessagesQuery.prefetch = async (gameId: string) =>
  queryClient.prefetchQuery([gameId, "game-messages"], () =>
    getMessagesByGameId(gameId),
  );

export function useScoringAndRankQuery(gameId: string) {
  return useQuery(
    [gameId, "scoring-and-rank"],
    () => getScoringAndRank(gameId),
    {
      refetchInterval: useSetting("queries.polling.short"),
    },
  );
}

useScoringAndRankQuery.prefetch = async (gameId: string) =>
  queryClient.prefetchQuery([gameId, "scoring-and-rank"], () =>
    getScoringAndRank(gameId),
  );

export function usePicksQuery(gameId: string) {
  return useQuery([gameId, "picks"], () => getPicksByGameId(gameId));
}

usePicksQuery.prefetch = async (gameId: string) =>
  queryClient.prefetchQuery([gameId, "picks"], () => getPicksByGameId(gameId));

export async function prefetch(gameId: string) {
  return Promise.all([
    useGameQuery.prefetch(gameId),
    useMessagesQuery.prefetch(gameId),
    useScoringAndRankQuery.prefetch(gameId),
    usePicksQuery.prefetch(gameId),
  ]);
}
