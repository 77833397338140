import { useQuery } from "react-query";

import { getPicksByGameId, getScoringAndRank } from "../../api";
import { queryClient } from "../../components";
import { useGameQuery } from "../../screens/game-dashboard/DashboardPage.data";
import { useSetting } from "../../settings";

export { useGameQuery };

export function usePicksQuery(gameId: string) {
  return useQuery([gameId, "picks"], () => getPicksByGameId(gameId));
}

usePicksQuery.prefetch = async (gameId: string) =>
  queryClient.prefetchQuery([gameId, "picks"], () => getPicksByGameId(gameId));

export function useScoringAndRankQuery(gameId: string) {
  return useQuery(
    [gameId, "scoring-and-rank"],
    () => getScoringAndRank(gameId),
    {
      refetchInterval: useSetting("queries.polling.short"),
    },
  );
}

useScoringAndRankQuery.prefetch = async (gameId: string) =>
  queryClient.prefetchQuery([gameId, "scoring-and-rank"], () =>
    getScoringAndRank(gameId),
  );

export async function prefetch(gameId: string) {
  return Promise.all([
    useGameQuery.prefetch(gameId),
    usePicksQuery.prefetch(gameId),
    useScoringAndRankQuery.prefetch(gameId),
  ]);
}
